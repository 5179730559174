import React from 'react';
import "./Header.css";

const Header = ({ menuIsClicked, setMenuIsClicked }) => {

  const buttonClassName = (
    `${menuIsClicked ? 'header__burger-button header__burger-button_active' : 'header__burger-button'}`
  )

  const clickOnNavBtn = () => {
    setMenuIsClicked(!menuIsClicked);
  }
  return (
      <header className="header" id="top">
        <p className="header__logo">ЖКХ.ИНФО</p>
        <nav className="header__navigation">
          <button className={buttonClassName} onClick={clickOnNavBtn}><span></span></button>
          <ul className={`header__list ${menuIsClicked ? 'header__list_active' : ''}`}>
            <li><a className="header__link" href="#adv-link">Преимущества</a></li>
            <li><a className="header__link" href="#pls-link">Зачем</a></li>
            <li><a className="header__link" href="#cost-link">Стоимость</a></li>
            <li><a className="header__link" href="#form-link">Присоединиться</a></li>
          </ul>
        </nav>
      </header>
  );
};

export default Header;
