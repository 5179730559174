import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import CookieAlert from "../CookieAlert/CookieAlert";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import "./DeleteUser.css";

const DeleteUser = ({ type }) => {
  const [values, setValues] = React.useState({});
  const [isValid, setIsValid] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("+7");
  const [isMessageSuccess, setIsMessageSuccess] = React.useState(false);

  React.useEffect(() => {
    if (phoneNumber === "") setPhoneNumber("+7");
  }, [setPhoneNumber, phoneNumber]);

  React.useEffect(() => {
    setValues({ ...values, type: type });
  }, []);

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (name === "phone") {
      setPhoneNumber(value.replace(/\D/g, "").replace(/^[\s\d+]/, "+7"));
    }

    setValues({ ...values, [name]: value });
    setIsValid(target.closest("form").checkValidity());
  };

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoaded(true);
    setIsValid(false);
    fetch("https://izba.kras-abs.ru/api/v3/user/deleteUser", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => {
        setIsValid(true);
        setIsLoaded(false);
        setIsMessageSuccess(true);
      })
      .catch((err) => {});
  }

  return (
    <>
      <CookieAlert />
      <header className="header" id="top">
        <p className="header__logo">ЖКХ.ИНФО</p>
      </header>
      <div className="delUser"></div>
      <div className="delUserForms">
        {" "}
        <div className="delUserForm">
          <h2 className="delUserFormTitle">
            Заявка на удаление аккаунта из системы ЖКХ.ИНФО
          </h2>
          <form
            className="delUserFormBody"
            id="request-container"
            method="post"
            action="#"
            name="request"
            noValidate
            onSubmit={handleSubmit}
          >
            <div
              className="delUserFormBodyReqText"
              style={{ textAlign: "center" }}
            >
              Для того чтобы запросить удаление данных аккаунта необходимо
              заполнить поле ниже
            </div>
            {/* {type === "user" && (
              <div className="delUserFormBodyInputField" id="name-input">
                <label htmlFor="name" className="delUserFormBodyLabel">
                  Введите ваш логин
                </label>
                <input
                  className="delUserFormBodyInput"
                  type="text"
                  placeholder="Введите логин"
                  name="name"
                  required
                  onChange={handleChange}
                />
              </div>
            )} */}

            <div className="delUserFormBodyInputField" id="phone-input">
              <label htmlFor="phone" className="delUserFormBodyLabel">
                Введите ваш номер телефона
              </label>
              <input
                className="delUserFormBodyInput"
                type="text"
                placeholder="Введите номер телефона"
                name="phone"
                required
                onChange={handleChange}
                maxLength="12"
                pattern="[01-9+]{12}"
                value={phoneNumber}
              />
            </div>

            {/* <div className="delUserFormBodyInputField" id="about-input">
              <div className="delUserFormBodyReq">
                <span>*</span>
                <p className="delUserFormBodyReqText">
                  {" "}
                  - обязательное для заполнения поле
                </p>
              </div>
            </div> */}

            <button
              className={
                isValid
                  ? "delUserFormBodyButton"
                  : "delUserFormBodyButton delUserFormBodyButtonDisabled"
              }
              type="submit"
              disabled={!isValid}
              id="form-button"
            >
              {isLoaded ? (
                <i className="delUserFormBodyButtonText" />
              ) : (
                "ОТПРАВИТЬ"
              )}
            </button>
          </form>
          <p className="delUserFormBodyPrivacyNotification">
            Отправляя эту форму Вы соглашаетесь с{" "}
            <Link to="/privacy" target="_blank">
              Политикой обработки персональных данных
            </Link>{" "}
            и даете согласие на обработку персональных данных компанией ООО «ЖКХ
            Инфо Красноярск»
          </p>
        </div>
      </div>

      <Footer />
      <InfoTooltip
        isInfoTooltipOpen={isMessageSuccess}
        isMessageSuccess={isMessageSuccess}
      />
    </>
  );
};

export default DeleteUser;
