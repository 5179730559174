import React from "react";
import ReactDOM from "react-dom";
import App from "./App/App";
import {BrowserRouter} from "react-router-dom"
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable'

import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
