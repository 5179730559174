import React from "react";
import { Link } from "react-router-dom";

import "./FormWithMap.css";

const FormWithMap = ({ setIsInfoTooltipOpen, setIsMessageSuccess }) => {
  const [values, setValues] = React.useState({});
  const [isValid, setIsValid] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("+7");

  React.useEffect(() => {
    if (phoneNumber === "") setPhoneNumber("+7");
  }, [setPhoneNumber, phoneNumber]);

  const handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    if (name === "phone") {
      setPhoneNumber(value.replace(/\D/g, "").replace(/^[\s\d+]/, "+7"));
    }

    setValues({ ...values, [name]: value });
    setIsValid(target.closest("form").checkValidity());
  };

  function closeModal() {
    setIsInfoTooltipOpen(false);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setIsLoaded(true);
    setIsValid(false);
    fetch("https://izba.kras-abs.ru/api/v2/createConnectRequest", {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => {
        if (res.status === 200) {
          setIsMessageSuccess(true);
          setIsInfoTooltipOpen(true);
          setTimeout(closeModal, 3000);
          setIsValid(true);
          setIsLoaded(false);
        } else {
          setIsMessageSuccess(false);
          setIsInfoTooltipOpen(true);
          setTimeout(closeModal, 3000);
          setIsValid(true);
          setIsLoaded(false);
        }
      })
      .catch((err) => {
        if (err) {
          setIsMessageSuccess(false);
          setIsInfoTooltipOpen(true);
          setTimeout(closeModal, 3000);
          setIsValid(true);
          setIsLoaded(false);
        }
      });
  }

  return (
    <section className="fwm" id="form-link">
      <div className="fwm__form">
        <h2 className="fwm__form-title">
          Вы заинтересованы? Тогда оставьте заявку удаленно, либо приходите к
          нам.
        </h2>
        <form
          className="form"
          id="request-container"
          method="post"
          action="#"
          name="request"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="form__input-field" id="name-input">
            <label htmlFor="name" className="form__label">
              Ваше имя<span>*</span>
            </label>
            <input
              className="form__input"
              type="text"
              placeholder="Введите имя"
              name="name"
              required
              onChange={handleChange}
            />
          </div>
          <div className="form__input-field" id="phone-input">
            <label htmlFor="phone" className="form__label">
              Номер телефона<span>*</span>
            </label>
            <input
              className="form__input"
              type="text"
              placeholder="Введите номер телефона"
              name="phone"
              required
              onChange={handleChange}
              maxLength="12"
              pattern="[01-9+]{12}"
              value={phoneNumber}
            />
          </div>
          <div className="form__input-field" id="about-input">
            <label htmlFor="text" className="form__label">
              Текст заявки<span>*</span>
            </label>
            <textarea
              className="form__input form__input_textarea"
              placeholder="Расскажите о Вашей УК. Мы обязательно с Вами свяжемся"
              name="text"
              required
              onChange={handleChange}
            />
            <div className="form__req-container">
              <span>*</span>
              <p className="form__star"> - обязательное для заполнения поле</p>
            </div>
          </div>

          <button
            className={
              isValid ? "form__button" : "form__button form__button_disabled"
            }
            type="submit"
            disabled={!isValid}
            id="form-button"
          >
            {isLoaded ? <i className="circle-preloader" /> : "ОТПРАВИТЬ"}
          </button>
        </form>
        <p className="privacy-notification">
          Отправляя эту форму Вы соглашаетесь с <Link to="/privacy" target="_blank">Политикой обработки персональных данных</Link> и 
          даете согласие на обработку персональных данных компанией ООО «ЖКХ Инфо Красноярск»
        </p>
      </div>
      <div className="fwm__map"></div>
    </section>
  );
};
export default FormWithMap;
