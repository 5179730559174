import React from "react";

import "./AdvantageCard.css";

const AdvantageCard = ({ advantage }) => {

  return (
    <div className="advantages__card">
      <img className="advantages__card-image" src={advantage.icon} alt="логотип карточки"></img>
      <h3 className="advantages__card-title">{advantage.title}</h3>
      <p className="advantages__card-description">{advantage.description}</p>
    </div>
  );
};

export default AdvantageCard;
