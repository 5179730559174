import React from 'react';
import "./CookieAlert.css";

const CookieAlert = () => {
    const hideCookieAlert = () => {
        document.getElementById("cookie-alert-container").classList.add("hiden")
    }

    return(
      <div className="cookie-alert-container" id = "cookie-alert-container">
        <div className='cookie-alert-text'>
          Мы используем cookie. Это позволяет нам анализировать
          взаимодействие посетителей с сайтом и делать его лучше.
          Продолжая пользоваться сайтом, Вы соглашаетесь с 
          использованием файлов cookie. Тем не менее, Вы всегда
          можете отключить файлы cookie в настройках Вашего браузера.
        </div>
        <div className='cookie-alert-text'>
        <button className='cookie-alert-button' onClick={hideCookieAlert}>Ок</button>
      </div>
      </div>
    )
}

export default CookieAlert