import React from "react";
import { YMInitializer } from "react-yandex-metrika";
import { Routes, Route } from "react-router-dom";
import Main from "../Main/Main";
import DeleteUser from "../DeleteUser/DeleteUser";

const App = () => {
  return (
    <>
      <YMInitializer accounts={[89196362]} />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/deluser" element={<DeleteUser type="mpi" />} />
        <Route path="/delexecutor" element={<DeleteUser type="mpj" />} />
        {/* <Route path="/privacy" element={<Privacy/>} /> */}
      </Routes>
    </>
  );
};

export default App;
