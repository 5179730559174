import React from "react";

import "./InfoTooltip.css";
import positivePath from "../vendor/images/Union.png";
import negativePath from "../vendor/images/Union2.png";

function InfoTooltip({ isInfoTooltipOpen, isMessageSuccess }) {

  return (
    <div className={isInfoTooltipOpen ? "popup popup_opened" : "popup"}>
      <div className="popup__container">
        {isMessageSuccess === true && (
          <img
            className="popup__info"
            src={positivePath}
            alt="Статус запроса"
          />
        )}
        {isMessageSuccess === false && (
          <img
            className="popup__info"
            src={negativePath}
            alt="Статус запроса"
          />
        )}
        {isMessageSuccess === true && (
          <h3 className="popup__title popup__title_info">
            Ваша заявка успешно отправлена!
          </h3>
        )}
        {isMessageSuccess === false && (
          <h3 className="popup__title popup__title_info">
            Что-то пошло не так. Повторите запрос или попробуйте позже
          </h3>
        )}
      </div>
    </div>
  );
}

export default InfoTooltip;
