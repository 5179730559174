import React from "react";
// import StoreButtons from "./StoreButtons/StoreButtons";

import "./About.css";
import iphonesPc from "../vendor/images/iPhonesPC.png";
import cableIllustration from "../vendor/images/cableIllustration.png";
import cost1 from "../vendor/images/cost1.png";
import cost2 from "../vendor/images/cost2.png";
import qrcode from "../vendor/images/qrcode.svg";

const About = () => {
  return (
    <section className="about">
      <div className="about__container">
        <h3 className="about__title">
          Некоторые бытовые задачи, которые можно решать с помощью смартфона:
        </h3>
        <p className="about__paragraph about__paragraph_check">
          <span className="about__check">&#10003;&#8194;</span>
          Оплатить коммунальные платежи
        </p>
        <p className="about__paragraph about__paragraph_check">
          <span className="about__check">&#10003;&#8194;</span>
          Передать показания счетчиков
        </p>
        <p className="about__paragraph about__paragraph_check">
          <span className="about__check">&#10003;&#8194;</span>
          Узнать новости дома и о плановых отключениях
        </p>
        <p className="about__paragraph about__paragraph_check">
          <span className="about__check">&#10003;&#8194;</span>
          Вызвать сантехника, провести внештатную проверку
        </p>
      </div>
      <div className="about__container about__iphone">
        <img
          alt="Изображение мобильного проложения"
          src={iphonesPc}
          className="about__image"
        />
      </div>
      <div className="about__container about__container_buttons">
        {/* <StoreButtons /> */}
        <img src={qrcode} alt="QR-Code"></img>
        <p className="about__qr-text">Ссылка на скачивание</p>
      </div>
      <div className="about__container">
        <h3 className="about__title">Доступность</h3>
        <p className="about__paragraph">
          Мобильное приложение вашей УК будет размещаться в AppStore и Google
          Play для бесплатного и добровольного скачивания жителями.
        </p>
      </div>
      <div className="about__container">
        <h3 className="about__title">Подключение</h3>
        <p className="about__paragraph">
          УК подключаются к системе через специальный коннектор. На данный
          момент мы подключились к таким системам как:
        </p>
        <p className="about__paragraph">
          🔗 <span>1С</span>, различные конфигурации
        </p>
        <p className="about__paragraph">
          🔗 <span>СПРИНТ-ЖКХ</span> (КрасИнформ)
        </p>
        <p className="about__paragraph">
          🔗 <span>ДС ЖКХ</span> (D-Soft)
        </p>
        <p className="about__paragraph">
          🔗 <span>Платформа ЖКХ</span>
        </p>
      </div>
      <div className="about__container">
        <img
          alt="Картинка подключения"
          src={cableIllustration}
          className="about__image"
        />
        <i id="cost-link" className="about__cost-anchor"></i>
      </div>
      <h3 className="about__title about__title_cost">Стоимость</h3>
      <div className="about__container about__container_cost">
        <img
          alt="Изображение оплаты"
          src={cost1}
          className="about__image about__image_cost"
        />
        <p className="about__paragraph about__paragraph_cost">
          УК <span>ежемесячно</span> оплачивает подписку в зависимости от
          количества Лицевых счетов.
        </p>
      </div>
      <div className="about__container about__container_cost">
        <img
          alt="Изображение оплаты"
          src={cost2}
          className="about__image about__image_cost"
        />
        <p className="about__paragraph about__paragraph_cost">
          Например, стоимость подписки УК с <span>3000</span> лицевыми счетами
          составит <span>11000</span> руб./мес.
        </p>
      </div>
      <div className="about__button-container">
        <a href="#form-link">
          <div className="about__button">УЗНАТЬ БОЛЬШЕ</div>
        </a>
      </div>
    </section>
  );
};

export default About;
