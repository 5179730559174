import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./SliderBlock.css";

const SliderBlockAdv = (props) => {
  const fadeStatus = props.pluses ? true : false;
  const classOfDots = props.pluses ? 'slick-dots slick-dots_white' : 'slick-dots'

  const settings = {
    arrows: false,
    dots: true,
    // swipeToSlide: true,
    lazyLoad: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 2,
    fade: fadeStatus,
    dotsClass: classOfDots,
  };

  return (
    <div style={{width: '100%'}}>
      <Slider {...settings}>
        {props.children}
      </Slider>
    </div>
  );
};

export default SliderBlockAdv;
