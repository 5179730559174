import React from "react";
import AdvantageCard from "./Card/AdvantageCard";
import SliderBlock from "../SliderBlock/SliderBlock";
import "./Advantages.css";
import quickIcon from "../vendor/images/icons/quickIcon.svg";
import easyIcon from "../vendor/images/icons/easyIcon.svg";
import comfortIcon from "../vendor/images/icons/comfortIcon.svg";

const Advantages = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const advantages = [
    {
      icon: quickIcon,
      title: "Быстро",
      description: "Заплатить за квартиру за 2-3 минуты.",
    },
    {
      icon: easyIcon,
      title: "Удобно",
      description: "Передать показания счетчиков",
    },
    {
      icon: comfortIcon,
      title: "Комфортно",
      description: "Сообщить в УК о перегоревшей лампочке",
    },
  ];

  function resize() {
    setScreenWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", resize);
    resize();

    return () => window.removeEventListener("resize", resize);
  });

  return (
    <section className="advantages" id="adv-link">
      <h2 className="advantages__title">Наши преимущества</h2>
      {screenWidth > 1172 && (
        <div className="advantages__grid-content">
          {advantages.map((advantage, index) => (
            <AdvantageCard key={index} advantage={advantage} />
          ))}
        </div>
      )}
      {screenWidth <= 1172 && (
        <SliderBlock ulContainer={true}>
            {advantages.map((advantage, index) => (
              <AdvantageCard key={index} advantage={advantage} />
            ))}
        </SliderBlock>
      )}
    </section>
  );
};

export default Advantages;
