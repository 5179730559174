import React from 'react';
import './Offer.css';

const Offer = ({ menuIsClicked }) => {
  return (
      <section className={menuIsClicked ? "offer offer_short" : "offer"}>
        <h1 className="offer__title">Удобный сервис для ваших жителей!</h1>
        <p className="offer__desctiption">Жители высоко оценят заботу о них и экономию их времени</p>
        <a href="#pls-link"><div className="offer__button">ПОДРОБНЕЕ</div></a>
      </section>
  );
};

export default Offer;
