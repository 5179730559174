import React from "react";

import "./Partners.css";

import rgSoftLogo from "../vendor/images/icons/rg-soft.png";
import krkLogo from "../vendor/images/icons/krkLogo.png";
import komfortLogo from "../vendor/images/icons/komfortLogo.png";
import pkLogo from "../vendor/images/icons/pkLogo.png";
import holmLogo from "../vendor/images/icons/holmLogo.png";
import PaymentLogo from "../vendor/images/icons/paymentLogo.png";

const Partners = () => {
  return (
    <section className="partners">
      <h2 className="partners__title">Нас выбирают</h2>
      <ul className="partners__list">
        <li>
          <a href="https://www.rg-soft.ru/" target="_blank" rel="noreferrer">
            <img
              src={rgSoftLogo}
              alt="RG SOFT логотип"
              className="partners__logo"
            />
          </a>
        </li>
        <li>
          <a href="https://kras-tko.ru/" target="_blank" rel="noreferrer">
            <img
              src={krkLogo}
              alt="КРК логотип"
              className="partners__logo"
            />
          </a>
        </li>
        <li>
          <a href="https://comfort-group.ru/" target="_blank" rel="noreferrer">
            <img
              src={komfortLogo}
              alt="Группа Комфорт логотип"
              className="partners__logo"
            />
          </a>
        </li>
        <li>
          <a href="https://holmservis.ru/" target="_blank" rel="noreferrer">
            <img
              src={holmLogo}
              alt="УК Холмсервис логотип"
              className="partners__logo"
            />
          </a>
        </li>
        {/* <li>
          <a href="https://pokvorota.ru/" target="_blank" rel="noreferrer">
            <img
              src={pkLogo}
              alt="Покровские ворота логотип"
              className="partners__logo"
            />
          </a>
        </li> */}
        <li>
          <a href="https://new.krasplat.ru/" target="_blank" rel="noreferrer">
            <img
              src={PaymentLogo}
              alt="Платежка логотип"
              className="partners__logo"
            />
          </a>
        </li>
      </ul>
    </section>
  );
};

export default Partners;
