import React from 'react';
import Header from "../Header/Header";
import Offer from "../Offer/Offer";
import Advantages from "../Advantages/Advantages";
import Pluses from "../Pluses/Pluses";
import About from "../About/About";
import Partners from "../Partners/Partners";
import FormWithMap from "../FormWithMap/FormWithMap";
import Footer from "../Footer/Footer";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import CookieAlert from '../CookieAlert/CookieAlert';

const Main = () => {
  const [menuIsClicked, setMenuIsClicked] = React.useState(false);
  const [isInfoTooltipOpen, setIsInfoTooltipOpen] = React.useState(false);
  const [isMessageSuccess, setIsMessageSuccess] = React.useState(null);       
  return (
    <>
      <CookieAlert />
      <Header
          menuIsClicked={menuIsClicked}
          setMenuIsClicked={setMenuIsClicked}
      />
      <Offer menuIsClicked={menuIsClicked} />
      <Advantages />
      <Pluses />
      <About />
      <Partners />
      <FormWithMap
          setIsInfoTooltipOpen={setIsInfoTooltipOpen}
          setIsMessageSuccess={setIsMessageSuccess}
      />
      <Footer />
      <InfoTooltip
          isInfoTooltipOpen={isInfoTooltipOpen}
          isMessageSuccess={isMessageSuccess}
      />
    </>
  )
}

export default Main