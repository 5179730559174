import React from "react";
import SliderBlock from "../SliderBlock/SliderBlock";

import "./Pluses.css";
import iphoneImage from "../vendor/images/iPhonesPC.png";

const Pluses = () => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const pluses = [
    {
      text: "Обратную связь о качестве оказываемых услуг",
    },
    {
      text: "Возможность мгновенно доносить до жителей важные новости",
    },
    {
      text: "Быстро и легко проводить тематические опросы или голосования",
    },
    {
      text:
        "Повлиять на собираемость платежей, от тех жильцов, которые просто забывают об этом",
    },
    {
      text:
        "Еще один кирпичик в формировании современной и комфортной городской среды",
    },
    {
      text: "Увеличение охвата рынка за счет цифровизации",
    },
  ];

  function resize() {
    setScreenWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", resize);
    resize();

    return () => window.removeEventListener("resize", resize);
  });
  return (
    <section className="pluses">
      <i className="pluses__anchor" id="pls-link"></i>
      <div className="pluses__needs">
        <h2 className="pluses__title">Все что нужно для УК</h2>
        <p className="pluses__subtitle">
          Благодаря данной системе ваша управляющая компания получит такие
          возможности:
        </p>
      </div>
      {screenWidth > 900 && (
        <>
          {pluses.map((pluse, index) => (
            <div key={index} className="pluses__card">
              <div className="pluses__circle">
                <p className="pluses__circle-number">{index + 1}</p>
              </div>
              <p className="pluses__card-text">{pluse.text}</p>
            </div>
          ))}
          <div className="pluses__card">
            <div className="pluses__circle">
              <p className="pluses__circle-number pluses__circle-number_dots">
                ...
              </p>
            </div>
            <p className="pluses__card-text">И многое, многое другое...</p>
          </div>
        </>
      )}
      {screenWidth <= 900 && (
        <>
        <div className="pluses__slider_container">
          <SliderBlock pluses={pluses}>
            {pluses.map((pluse, index) => (
              <div key={index} className="pluses__card">
                <div className="pluses__circle">
                  <p className="pluses__circle-number">{index + 1}</p>
                </div>
                <p className="pluses__card-text">{pluse.text}</p>
              </div>
            ))}
            <div className="pluses__card">
              <div className="pluses__circle">
                <p className="pluses__circle-number pluses__circle-number_dots">
                  ...
                </p>
              </div>
              <p className="pluses__card-text">И многое, многое другое...</p>
            </div>
          </SliderBlock>
        </div>
        <img alt="Картинка с приложением" src={iphoneImage} className="pluses__image"/>
        </>
      )}
    </section>
  );
};

export default Pluses;
